@font-face {
  font-family: 'icomoon';
  src:  url('font/icomoon.eot?ko48e1');
  src:  url('font/icomoon.eot?ko48e1#iefix') format('embedded-opentype'),
    url('font/icomoon.ttf?ko48e1') format('truetype'),
    url('font/icomoon.woff?ko48e1') format('woff'),
    url('font/icomoon.svg?ko48e1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconball-"], [class*=" iconball-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconball-SNOOKER2:before {
  content: "\7777";
}
.iconball-BASKETBALL:before {
  content: "\7778";
}
.iconball-more:before {
  content: "\7779";
}
.iconball-BASEBALL:before {
  content: "\777a";
}
.iconball-BOXING:before {
  content: "\777b";
}
.iconball-CRICKET:before {
  content: "\777c";
}
.iconball-FOOTBALL:before {
  content: "\777d";
}
.iconball-GAME:before {
  content: "\777e";
}
.iconball-GOLF:before {
  content: "\777f";
}
.iconball-HOCKEY:before {
  content: "\7780";
}
.iconball-ICEHOCKEY:before {
  content: "\7781";
}
.iconball-MMA:before {
  content: "\7782";
}
.iconball-SNOOKER .path1:before {
  content: "\7783";
  color: rgb(0, 0, 0);
}
.iconball-SNOOKER .path2:before {
  content: "\7784";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.iconball-SNOOKER .path3:before {
  content: "\7785";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.iconball-TABLETENNIS:before {
  content: "\7786";
}
.iconball-TENNIS:before {
  content: "\7787";
}
.iconball-VOLLEYBALL:before {
  content: "\7788";
}
.iconball-BADMINTON:before {
  content: "\7789";
}
.sport-menu1 i{
	display:block;
	font-size: 20px;
	margin-bottom: 6px;
}