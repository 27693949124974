.top-menu .mega-current-menu-item {
    background: #0000004d !important;
    text-shadow: 0 0 5px white, 0 0 15px white;
    border-radius: 10px !important;
}

.top-menu li:hover {
    background: #0000004d !important;
    text-shadow: 0 0 5px white, 0 0 15px white;
    border-radius: 10px !important;
}

.menu-mobile a {
    border-bottom: 1px solid #222222 !important;
}

.menu-mobile .mega-current-menu-item a {
    border-bottom: unset !important;
}

.sport-menu .mega-current-menu-item {
    background: #0000004d !important;
    text-shadow: 0 0 5px white, 0 0 15px white;
    border-radius: 10px !important;
}

.sport-menu li:hover {
    background: #0000004d !important;
    text-shadow: 0 0 5px white, 0 0 15px white;
    border-radius: 10px !important;
}

.more-sport .mega-current-menu-item {
    background: #0000004d !important;
    text-shadow: 0 0 5px white, 0 0 15px white;
    border-radius: 10px !important;
}

.more-sport li:hover {
    background: #0000004d !important;
    text-shadow: 0 0 5px white, 0 0 15px white;
    border-radius: 10px !important;
}

.vs {
    padding: 0px;
}

.home {
    /*text-align:right;*/
}

.away {
    text-align: left;
}

.play-list td {
    border: none;
    padding: 10px 5px;
}

.play-list tr {
    background: #333333 !important;
    padding: 5px !important;
}

.match-date td {
    padding: 10px 10px;
}

.match-date {
    background: #000;
    color: #ccc;
}

.status-live img {
    width: 50px;
    vertical-align: middle !important;
}

.status-vs img {
    width: 25px;
}

.status-vs {
    text-align: center;
}

/* DivTable.com */
.divTable {
    display: table;
    width: 100%;
    padding-bottom: 3px;
    border-radius: 10px !important;
    color: #ECF1F2;
    margin-bottom: 6px;
    background: rgba(51, 54, 82, 0.9);
    border: 1px solid;
    border-color: rgba(83, 86, 90, 0.3);
}

.divTableRow {
    display: table-row;
}

.divTableHeading {
    background-color: #eee;
    display: table-header-group;
}

.divTableCell,
.divTableHead {
    /*border: 1px solid #999999;*/
    display: table-cell;
    padding: 8px 10px;
    vertical-align: middle;
}

.divTableHeading {
    background-color: #eee;
    display: table-header-group;
    font-weight: bold;
}

.divTableFoot {
    background-color: #eee;
    display: table-footer-group;
    font-weight: bold;
}

.divTableBody {
    display: table-row-group;
}

.divTableCell {
    /*	border-bottom:3px solid #111111;*/
}

.divTableRow {
    /*background:#181818;*/
}

.divTable:hover {
    background: rgba(51, 54, 82, 1);
}

/* DivTable.com */

div.time-play {
    width: 5%;
    text-align: center;
    font-size: 13px;
}

div.status-match {
    width: 15%;
    text-align: center;
    padding: 0px;
}

.button-paly {
    text-align: center;
    padding: 0px;
}

.button-paly img {
    vertical-align: middle;
    padding: 0;
    width: 20px;
}

div.button-paly {
    width: 15%;
    text-align: center;
}

.fa-play {
    color: #ECF1F2 !important;
    border-radius: 50%;
    border: 1px solid #ECF1F2;
    padding: 6px;
    font-size: 8px;
}

div.away-team {
    width: 55%;
    text-align: left;
}

div.data-info {
    width: 15%;
}

div.home-team {
    width: 40%;
    text-align: right;
}

.league {
    text-align: left;
    padding: 10px 5px;
    color: #BBC8DE;
    font-size: 16px;
    font-family: kanit;
    /*border-bottom: 4px solid #343434;*/
    /*background:linear-gradient(to bottom, #313131 0%, #262626 5%, #262626 5%, #262626 5%, #050505 100%);*/
}

#detail-tab table {
    border: none;
    background: rgba(21, 27, 37, 0.4);
}

#detail-tab th {
    border: none;
    background: #0B0B0B;
}

#detail-tab td {
    border: none;
}

#detail-tab td {
    padding: 5px;
    text-align: center;
    vertical-align: middle;
}

#detail-tab .home-player {
    text-align: left;
}

#detail-tab .away-player {
    text-align: right;
}

.playernumber,
.lineuptype {
    color: #ffcc00;
}

.border-1 {
    border-left: 2px solid #900000;
}

.date-play {
    background: #DA1212;
    color: #fff;
    text-align: center;
    font-family: kanit;
    padding: 2px;
    font-size: 18px;
}

.card {
    width: 120px;
    height: 80px;
    margin: 5px;
    justify-content: center;
    align-items: center;
    color: white;
    border: 0;
    display: flex;
    background: -webkit-linear-gradient(180deg, #000033, #003c77, #000033);
    /* สำหรับ Safari */
    background: -moz-linear-gradient(180deg, #000033, #003c77, #000033);
    /* สำหรับ Firefox */
    background: -o-linear-gradient(180deg, #000033, #003c77, #000033);
    /* สำหรับ Opera */
    background: linear-gradient(180deg, #000033, #003c77, #000033);
    /* มาตรฐาน */
    cursor: pointer;
    text-align: center;
    /* ให้ข้อความอยู่กึ่งกลางใน flexbox */
}

.live-now {
    animation: livemove 0.7s infinite;
}

@keyframes livemove {
    from {
        color: #ff2626;
    }

    to {
        color: #ECF1F2;
    }
}

._Vs {
    background: #000;
    padding: 5px;
    border-radius: 5px;
    color: #FF6501;
    font-size: 13px;
}

.no-match {
    font-family: kanit;
    text-align: center;
    color: gold;
}

div[class*="sporttable-"] {
    display: none;
}

div.sporttable-FOOTBALL {
    display: table-cell;
}

.loader {
    width: 2.8px;
    height: 1.8px;
    display: block;
    margin: 20px auto;
    position: relative;
    border-radius: 4px;
    color: #fff;
    background: currentColor;
    box-sizing: border-box;
    animation: animloader 0.3s 0.3s linear infinite alternate;
}

.loader::after,
.loader::before {
    content: "";
    box-sizing: border-box;
    width: 2.8px;
    height: 1.8px;
    border-radius: 4px;
    background: currentColor;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 8px;
    animation: animloader 0.3s 0.45s linear infinite alternate;
}

.loader::after {
    top: -8px;
    animation-delay: 0s;
}

@keyframes animloader {
    0% {
        width: 4.8px;
    }

    100% {
        width: 38px;
    }
}

.close-more .dialog-close-button {
    margin-right: -25px;
    background: #181818;
    padding: 5px;
}

.tag-news a {
    font-size: 13px;
    padding: 8px 12px;
    margin: 0 5px 5px 0;
    line-height: 17px;
    color: rgb(124, 120, 120);
    height: 32px;
    background-color: #181818;
    border-radius: 30px;
    display: inline-block !important;
}

/*mobile style*/
@media screen and (max-width: 600px) {
    .time-play i {
        display: none;
    }

    .league {
        font-size: 15px;
    }

    .date-play {
        font-size: 18px;
    }
}

.team-hw {
    display: block;
    padding: 2px 2px;
}

.pic-team {
    width: 20px;
    vertical-align: middle;
    margin-right: 5px;
}

.pic-league {
    vertical-align: middle;
    width: 35px !important;
    margin-right: 5px;
}

div.data-info a {
    color: #ECF1F2 !important;
}

selector ul {
    /*display: flex !important;*/
    flex-wrap: nowrap !important;
    white-space: nowrap !important;
    overflow-x: auto !important;
}

selector ul::-webkit-scrollbar {
    display: none !important;
}

selector ul {
    /*display: flex !important;*/
    flex-wrap: nowrap !important;
    white-space: nowrap !important;
    overflow-x: auto !important;
}

selector ul::-webkit-scrollbar {
    display: none !important;
}

/*menu sport*/

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    font-size: 18px;
}

.sidenav a {
    padding: 8px 8px 5px 30px;
    text-decoration: none;
    font-family: kanit;
    color: #d2d2d2;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}

.sport-menu1 ul {
    list-style-type: none;
    margin: 0;
    padding: 5px;
    overflow: hidden;
    background-color: rgba(14, 5, 15, 0.8);
    text-align: center;
    border-radius: 10px;
}

.sport-menu1 li {
    display: inline-block;
}

.sport-menu1 li a,
.more-menu {
    display: block;
    color: white;
    text-align: center;
    padding: 10px 16px;
    text-decoration: none;
    font-family: kanit;
}

.sport-menu1 ul {
    /*display: flex !important;*/
    flex-wrap: nowrap !important;
    white-space: nowrap !important;
    overflow-x: auto !important;
    text-align: center !important;
}

.sport-menu1 ul::-webkit-scrollbar {
    display: none !important;
}

.sport-menu1 ul {
    /*display: flex !important;*/
    flex-wrap: nowrap !important;
    white-space: nowrap !important;
    overflow-x: auto !important;
    text-align: center !important;
}

.sport-menu1 ul::-webkit-scrollbar {
    display: none !important;
}

/*end menusport*/

/*menu active*/
.sport-menu1 .active {
    background: #0000004d !important;
    text-shadow: 0 0 5px white, 0 0 15px white;
    border-radius: 10px !important;
}

.sport-menu1 li a:hover {
    background: #0000004d !important;
    text-shadow: 0 0 5px white, 0 0 15px white;
    border-radius: 10px !important;
}

.side-menu .active {
    /*background: #0000004d !important;*/
    text-shadow: 0 0 5px white, 0 0 15px white;
    border-radius: 10px !important;
}

.side-menu a {
    color: #fff;
    font-family: kanit;
    text-decoration: none;
    font-size: 17px;
}

.side-menu li {
    padding: 7px 0px;
}

.side-menu li a:hover {
    background: #0000004d !important;
    text-shadow: 0 0 5px white, 0 0 15px white;
    border-radius: 10px !important;
    color: #fff;
}

.heading-title {
    font-size: 1.75rem;
    text-align: center;
    font-family: kanit;
    font-weight: normal;
    color: #F4D800;
}

.team-vs span {
    display: inline-block;
}

.team-vs {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 1px solid;
    border-bottom-color: currentcolor;
    margin-bottom: 30px;
    border-color: gold;
}

.vs- {
    padding: 0px 8px;
    color: gold;
    font-size: 25px;
    font-weight: bold;
}

.custom-ul .actived {
    background: #0000004d !important;
    text-shadow: 0 0 5px white, 0 0 15px white;
    border-radius: 10px !important;
}

.custom-ul li:hover {
    background: #0000004d !important;
    text-shadow: 0 0 5px white, 0 0 15px white;
    border-radius: 10px !important;
}

.all-tab {
    color: #ECF1F2 !important;
}

.menu-iner {

    background: #151B25 !important;

}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}